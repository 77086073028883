import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const ContactPage = ({ data, pageContext }) => {
  
  const contacts = data.allMdx.nodes;

  return (
    <Layout title="Kontakt" pageContext={pageContext}>
      <section className="section">
        <div className="container">
        
          <h1 className="title">So erreichen Sie uns</h1>
          
          <div className="tile is-ancestor">
          
          {
            contacts.map((contact) => (
              <div className="tile is-parent">
                <div className="tile is-child is-flex">
                
                  <div className="card equal-height" style={{width: "100%"}}>
                  
                    <div className="card-image has-background-light" style={{filter: "saturate(0.5)"}}>
                      {contact.frontmatter.hero_image && <GatsbyImage className="image" image={getImage(contact.frontmatter.hero_image)} alt={contact.frontmatter.hero_image_alt} />}
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <div className="block"><h1 className="title is-size-4">{contact.frontmatter.title}</h1></div>
                          <ul className="block">
                            <li>Mail <a href={`mail:${contact.frontmatter.email}`}>{contact.frontmatter.email}</a></li>
                            <li>Telefon <a href={`tel:${contact.frontmatter.phone}`}>{contact.frontmatter.phone}</a></li>
                          </ul>
                          <div className="block">
                            <span className="icons">
                            <a href={contact.frontmatter.linkedin} className="icon" target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={faLinkedin} size="lg" />  
                            </a>
                            {' '}
                            <a href={contact.frontmatter.twitter} className="icon" target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={faTwitter} size="lg" />
                            </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            ))
          }
          
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`query ContactPage {
  allMdx(filter: {frontmatter: {templateKey: {eq: "contact"}}}) {
    nodes {
      frontmatter {
        title
        twitter
        linkedin
        email
        phone
        pgp_keyfile {
          publicURL
          name
          extension
        }
        pgp_fingerprint
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        hero_image_alt
      }
      body
      slug
    }
  }
}
`

export default ContactPage;
